import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import "./style.scss"

import ContactModal from "../../../components/contactModal"
import { navigate } from "gatsby"

import WhiteBg from "/static/cases/jingniao/白色底图.png"
import MainImg from "/static/cases/jingniao/主图.png"
import SolutionIcon1 from "/static/cases/jingniao/解决方案icon_1.png"
import SolutionIcon2 from "/static/cases/jingniao/解决方案icon_2.png"
import SolutionIcon3 from "/static/cases/jingniao/解决方案icon_3.png"
import SolutionIcon4 from "/static/cases/jingniao/解决方案icon_4.png"

const Jingniao = () => {
  const modalRef = useRef()
  return (
    <Layout>
      <ContactModal ref={modalRef} />
      <Seo
        title="鲸鸟跨境电商小包装载API服务_装箱管理软件-蓝胖智汇"
        keywords="跨境电商，装箱软件，拼箱软件，切箱算法"
        description="蓝胖智汇专注自研装箱管理软件、智能装箱码垛系统，为客户提供仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="../case-banner.png"
          alt="鲸鸟跨境电商小包装载API服务_装箱管理软件-蓝胖智汇"
          className="w-full"
        />
        <div className="absolute top-28 left-24 text-white text-3xl">
          成功案例
        </div>
      </div>
      <div>
        <div className="container m-auto">
          <div id="lead">
            <img src={WhiteBg} alt="底图" />
            <div className="giant">
              <h1 className="inline-block">鲸鸟快递</h1> <span>本土企业</span>
              <span>海外地区</span>
              <span>跨境电商</span>
            </div>
            <div className="services">
              提供的产品与服务：装满满、企业定制化服务、提供跨境集运小包装载API服务
            </div>
          </div>
          <div id="project-background">
            <div className="left">
              <div className="title">项目背景</div>
              <div className="para">
                鲸鸟，从属于深圳市顺友跨境物流有限公司旗下，是一个专注于国际快递业务的创新智能型平台。顺友在跨境物流领域深耕十余年，总部位于广东省深圳市，在全球多个城市设有分支机构和包裹转运中心，服务覆盖200余个国家和地区，日均处理包裹量超过50万件。
              </div>
              <div className="para">
                鲸鸟转运业务流程采用海外团长制，由海外团长统一下达含有多个货主的订单需求。货主线上采购后，小件包裹会到达鲸鸟仓库完成装箱再进行转运。在此基础上，需一次性完成单一订单不同货主小件包裹单独装箱、尾货拼箱以及特殊尺寸货物单独装箱的一整套计算流程。
              </div>
              <div className="para">
                蓝胖子与鲸鸟深度合作，为其提供跨境电商小包装载流程优化方案，解决国际快递寄件难、资费高、时效慢等问题。
              </div>
            </div>
            <img src={MainImg} alt="跨境电商 鲸鸟跨境" />
          </div>
        </div>
        <div id="pain-point">
          <div className="content">
            <div className="title">客户痛点</div>
            <div className="points">
              <div className="point point1">
                每个订单涉及货物类型众多、尺寸不一，计算难度大
              </div>
              <div className="point point2">
                双十一、618等电商消费旺季需满足海量订单多，处理效率低，计算时间长
              </div>
              <div className="point point3">
                计算不精确导致转运箱空间体积浪费
              </div>
              <div className="point point4">
                同时前期销售运费估计不准确导致后续补款退款流程处理繁杂
              </div>
            </div>
          </div>
        </div>
        <div id="solutions">
          <div className="title">解决方案</div>
          <div className="about">
            提供针对跨境电商小包装载流程优化方案，并提供API接口，使客户在WMS系统迅速调用算法，并在后续二期过程中根据具体业务环节异步调用。
          </div>
          <div className="cards">
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon1} alt="icon" />
                <div className="text">
                  提供单一订单内不同维度先分后混模式装载，例如可满足同一订单内优先满足满载货主先装，尾货再拼装模式
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon2} alt="icon" />
                <div className="text">
                  提供多种规格容器点选，按需求优先使用常用规格，特殊尺寸货物单独拼装
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon3} alt="icon" />
                <div className="text">
                  提供计算最小外接矩形计算，以实际拼装货物的尺寸，进行销售预估运费
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon4} alt="icon" />
                <div className="text">
                  提供运费成本优化模型，不断优化不同容器成本系数，使端到端联运混装业务成本最低
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="benefits">
          <div className="content">
            <div className="title">项目效益</div>
            <div className="cards">
              <div className="card">
                <div className="head">2分钟</div>
                <div className="desc">平均单个订单规划时间</div>
              </div>
              <div className="card">
                <div className="head">95%</div>
                <div className="desc">平均容器装载率约</div>
              </div>
              <div className="card">
                <div className="head">200次</div>
                <div className="desc">电商旺季可支持每天计算调用次数至少</div>
              </div>
              <div className="card">
                <div className="head">99%</div>
                <div className="desc">销售前期运费预估准确率</div>
              </div>
              <div className="card">
                <div className="head">8-15%</div>
                <div className="desc">成本优化模型下成本节约</div>
              </div>
              <div className="card">
                <div className="head">直接调用</div>
                <div className="desc white-nowrap">
                  API调用在不打破原有业务系统形态下，无需部署直接调用
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="misc">
          <div className="content">
            <div className="qa">
              <div className="title">其他问题</div>
              <div className="detail">
                告诉我们您需要解决什么问题，专家会帮助您找到最适合您的解决方案
              </div>
              <button
                onClick={() => modalRef.current.openModal()}
                className="contact-us-now"
                gtm-id="cases-contact-us"
              >
                联系我们
              </button>
            </div>
            <div className="try">
              <div className="product">
                <div className="about">开始体验 装满满-精简版</div>
                <div
                  className="go check-zmm-lite"
                  onClick={() =>
                    window.open("https://zmm-lite.doraopt.com?ref=h")
                  }
                >
                  立即体验
                </div>
              </div>
              <div className="product">
                <div className="about">更多产品</div>
                <div
                  className="go check-products"
                  onClick={() => navigate("/products")}
                >
                  查看更多产品
                </div>
              </div>
              <div className="product">
                <div className="about">继续浏览</div>
                <div
                  className="go check-cases"
                  onClick={() => navigate("/cases")}
                >
                  查看更多成功案例
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Jingniao
